<template>
  <section id="sec-3">
    <div class="porscheContainer">
      <div class="porsche"></div>
    </div>
    <LayoutSection>
      <template #title>Location de voiture</template>
      <template #text>
        <p>
          Une promenade en porsche vous tente ?<br />
          Nous vous proposons une location de porsche en longue ou courte
          durée.<br />
          Contactez-nous pour connaître la disponibilité.
        </p>
      </template>
    </LayoutSection>
    <span id="line-3" class="line">
      <svg height="160" width="160">
        <line
          x1="0"
          y1="0"
          x2="150"
          y2="150"
          style="stroke: rgba(0, 0, 0, 0.8); stroke-width: 1"
        />
      </svg>
    </span>
    <span id="line-4" class="line">
      <svg height="160" width="160">
        <line
          x1="0"
          y2="0"
          x2="150"
          y1="150"
          style="stroke: rgba(0, 0, 0, 0.8); stroke-width: 1"
        />
      </svg>
    </span>
  </section>
</template>

<script>
import LayoutSection from "./LayoutSection.vue";
export default {
  name: "Location",
  components: {
    LayoutSection,
  },
};
</script>

<style lang="scss" scoped>
#sec-3 {
  position: relative;
  overflow: hidden;
}
#line-3 {
  left: -100px;
  top: -100px;
}
#line-4 {
  left: 72%;
  bottom: -60px;
}
.porscheContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  overflow: hidden;
}
.porsche {
  position: absolute;
  top: 50%;
  right: -50px;
  background: url(../assets/images/Porsche_Thin.svg) no-repeat bottom center;
  background-size: 100%;
  width: 120%;
  height: 100%;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1) translateY(-50%);
}
@media (min-width: 500px) {
  .porsche {
    background: url(../assets/images/Porsche_Thin.svg) no-repeat center center;
    background-size: 100%;
    width: 100%;
  }
}
@media (min-width: 550px) {
  #sec-3 {
    overflow: visible;
  }
}
@media (min-width: 800px) {
  #line-4 {
    left: 52%;
  }
}
@media (min-width: 1400px) {
  #line-3 {
    left: -50px;
    top: -10px;
  }
}
</style>