<template>
  <div class="container">
    <div class="blockText">
      <h2><slot name="title"></slot></h2>
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutSection",
  data() {
    return {};
  },
};
</script>