<template>
  <div id="app">
    <Nav
      @scroll-event="menu"
      @scroll-event-top="menuTop"
      @mentionOff="mentionOff"
    />
    <div class="main" v-if="!mention">
      <Top @scroll-event="menu" />
      <Entreprise />
      <Transport />
      <Location />
      <Contact />
    </div>
    <div class="main" v-if="mention"><Mentions /></div>
    <Footer @mentionOn="mentionOn" />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import Top from "./components/Top.vue";
import Entreprise from "./components/Entreprise.vue";
import Transport from "./components/Transport.vue";
import Location from "./components/Location.vue";
import Contact from "./components/Contact.vue";
import Mentions from "./components/Mentions.vue";

export default {
  name: "App",
  data() {
    return {
      mention: false,
    };
  },
  components: {
    Nav,
    Footer,
    Top,
    Entreprise,
    Transport,
    Location,
    Contact,
    Mentions,
  },
  methods: {
    menu(number) {
      document.getElementById("sec-" + number).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    },
    menuTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    mentionOn() {
      this.mention = true;
    },
    mentionOff() {
      this.mention = false;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
</style>
