<template>
  <main>
    <div class="container">
      <h2>Mentions légales</h2>

      <h3>Propriétaire et Responsable de publication</h3>
      <p>COLEXO</p>
      <p>
        1440 Chemin d'Opeina
        <br />26400 Grâne
      </p>
      <p>
        <a href="mainto:contact@colexo.fr">contact@colexo.fr</a>
      </p>
      <p>N° SIRET : 519 425 375 00010</p>

      <h3>Hébergeur</h3>
      <p><strong>OVH</strong> - 2 rue Kellermann 59100 Roubaix</p>
      <h3>Direction de la publication</h3>
      <p>
        Le directeur de la publication est Monsieur Alain ASTRUC, en sa qualité
        de Président de la société COLEXO.
      </p>
      <h3>Utilisation d’images</h3>
      <p>
        Tous les éléments graphiques du site appartiennent à l’entreprise,
        conforment à l’article L. 122-4 du Code de la propriété intellectuelle,
        toute utilisation de ces éléments sans autorisation écrite particulière
        et préalable est strictement interdite.
      </p>
      <h3>Données personnelles</h3>
      <p>
        Ce site ne collecte pas d'informations personnelles relatives à
        l’utilisateur. Le site n'utilise pas Google Analytics ou tout autre
        service qui est suseptible d'utiliser des cookies.
      </p>
      <p>
        Ce site n’a pas fait l’objet d’une déclaration de conformité auprès de
        la CNIL.
      </p>
    </div>
  </main>
</template>

<script>
export default {
  neme: "Mentions",
};
</script>
<style lang="scss" scoped>
main {
  background: linear-gradient(272.35deg, #3e4c99 5.38%, #111065 93.7%);
  padding: 20px 0 50px 0;
  color: white;
  a {
    color: white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: rgba(white, 0.6);
    }
  }
}
h3 {
  margin-top: 50px;
}
@media (min-width: 768px) {
  main {
    padding: 70px 0 100px 0;
  }
}
</style>