<template>
  <footer>
    <div class="container">
      <span>Copyright {{ currentDateTime() }} &nbsp;&copy;COLEXO</span>
      <span class="href" @click="mentionOn">Mentions légales</span>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  methods: {
    mentionOn() {
      this.$emit("mentionOn");
    },
    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear();
      return date;
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  height: 40px;
  background-color: black;
  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.85em;
    > * {
      margin: 0 15px;
    }
  }
  .href {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>