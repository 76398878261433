<template>
  <header :class="{ fixed: !isFixed }">
    <nav>
      <h1 @click="scrollEventTop(), mentionOff()" class="logo">COLEXO</h1>
      <div class="nav-right">
        <span @click="scrollEvent(1), mentionOff()">Entreprise</span>
        <span @click="scrollEvent(2), mentionOff()">Transport</span>
        <span @click="scrollEvent(3), mentionOff()">Location de voiture</span>
        <span @click="scrollEvent(4), mentionOff()">Contact</span>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "Nav",
  data() {
    return {
      isFixed: true,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    scrollEvent(number) {
      this.$emit("scroll-event", number);
    },
    scrollEventTop() {
      this.$emit("scroll-event-top");
    },
    mentionOff() {
      this.$emit("mentionOff");
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 88) {
        return;
      }
      this.isFixed = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  z-index: 100;
  transition: 0.1s all ease-out;
  background-color: white;
  width: 100%;
  height: 70px;
  &.fixed {
    box-shadow: 0 2px 10px rgba(97, 93, 93, 0.4);
    height: 50px;
    h1 {
      font-size: 1.8em;
    }
    nav {
      height: 50px;
    }
  }
}
nav {
  max-width: 1200px;
  margin: auto;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  color: black;
  a {
    color: black;
  }
  h1 {
    cursor: pointer;
    font-size: 1.8em;
    font-family: Avenir;
    font-weight: $fwregular;
    transition: all 0.3s;
  }
  .nav-right {
    display: none;
    justify-content: space-around;
    span {
      margin: auto 10px;
      cursor: pointer;
      transition: all 0.3s;
      border-bottom: 3px solid transparent;
      padding-bottom: 8px;
      padding-top: 11px;
      &:hover {
        border-bottom-color: #000;
      }
    }
  }
}
@media (min-width: 768px) {
  header {
    height: 100px;
  }
  nav {
    height: 100px;
    .nav-right {
      display: flex;
    }
    h1 {
      font-size: 2.3em;
    }
  }
}
</style>