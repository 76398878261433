<template>
  <section id="sec-1">
    <LayoutSection>
      <template #title>Entreprise</template>
      <template #text>
        <p>
          Implantée dans la Drôme, nous sommes une entreprise à taille humaine,
          toujours à l’écoute du client. Nos valeurs reposent sur notre sens du
          service et notre professionalisme.
        </p>
      </template>
    </LayoutSection>
  </section>
</template>

<script>
import LayoutSection from "./LayoutSection.vue";
export default {
  name: "Entreprise",
  components: {
    LayoutSection,
  },
};
</script>

<style scoped lang="scss">
#sec-1 {
  background: url(../assets/images/paysage.jpg) no-repeat center 130px;
  background-size: 100%;
  position: relative;
  overflow: hidden;
}
@media (min-width: 450px) {
  #sec-1 {
    background: url(../assets/images/paysage.jpg) no-repeat center 70px;
    background-size: 100%;
  }
}
@media (min-width: 600px) {
  #sec-1 {
    .blockText {
      width: 60%;
    }
    background: url(../assets/images/paysage.jpg) no-repeat right bottom;
    background-size: 75%;
    &::before {
      content: "";
      display: block;
      position: absolute;
      background-color: white;
      width: 50%;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: 0;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      background-color: white;
      width: 50%;
      height: 100%;
      left: 26%;
      bottom: 0;
      z-index: 0;
      transform: skewY(-50deg);
    }
  }
}
@media (min-width: 1198px) {
  #sec-1 {
    background: url(../assets/images/paysage.jpg) no-repeat right center;
    background-size: 75%;
  }
}
</style>
