<template>
  <section id="top">
    <div class="container">
      <div id="topTransport" class="icon" @click="scrollEvent(2)">
        <span class="image"></span>
        <span class="text">Transport</span>
      </div>
      <div id="topPlus">+</div>
      <div id="topLocation" class="icon" @click="scrollEvent(3)">
        <span class="image"></span>
        <span class="text">Location</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Top",
  methods: {
    scrollEvent(number) {
      this.$emit("scroll-event", number);
    },
  },
};
</script>

<style lang="scss" scoped>
#top {
  background: linear-gradient(272.35deg, #0d63b3 5.38%, #4ce0d7 93.7%);
  min-height: 300px;
  padding-top: 30px;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
  }
  .icon {
    cursor: pointer;
    > * {
      display: block;
      text-align: center;
    }
    .image {
      height: 150px;
      width: 150px;
      border: 5px solid #ffffff;
      border-radius: 50%;
    }
    .text {
      color: white;
      font-size: 1.4em;
      text-transform: uppercase;
      margin: 20px auto;
    }
  }
  #topTransport {
    .image {
      background: url("../assets/images/Camion_FatLine.svg") no-repeat center
        center;
      background-size: 80%;
    }
  }
  #topLocation {
    .image {
      background: $yellow url(../assets/images/Porsche_Fat.svg) no-repeat center
        center;
      background-size: 85%;
    }
  }
  #topPlus {
    font-size: 70px;
    color: #ffffff;
    margin: 0 0 10% 0;
  }
}
@media (min-width: 500px) {
  #top {
    min-height: 340px;
    .container {
      padding: 40px;
      flex-direction: row;
    }
    .icon .text {
      font-size: 1.8em;
    }
  }
  #topPlus {
    font-size: 100px;
    margin: 5% 10% auto 10%;
  }
}
@media (min-width: 768px) {
  #top {
    height: 430px;
    padding-top: 30px;
    .icon {
      .image {
        height: 240px;
        width: 240px;
      }
      .text {
        font-size: 2.2em;
      }
    }
    #topPlus {
      font-size: 124px;
      margin: 8% 10% auto 10%;
    }
  }
}
@media (min-width: 1024px) {
  #top {
    height: 533px;
    padding-top: 60px;
    .icon .image {
      height: 316px;
      width: 316px;
    }
    #topPlus {
      font-size: 144px;
      margin: auto 10%;
    }
  }
}
</style>