<template>
  <section id="sec-4">
    <LayoutSection>
      <template #title>Contact</template>
      <template #text>
        <p>N'hésitez pas à nous contacter pour toute demande.</p>
        <p><a href="mailto:contact@colexo.fr">contact@colexo.fr</a></p>
      </template>
    </LayoutSection>
  </section>
</template>

<script>
import LayoutSection from "./LayoutSection.vue";
export default {
  name: "Contact",
  components: {
    LayoutSection,
  },
};
</script>

<style lang="scss" scoped>
#sec-4 {
  color: white;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    $yellow 0%,
    $yellow 46%,
    rgba(255, 255, 255, 1) 49%
  );
  &::before {
    content: "";
    display: block;
    background: linear-gradient(272.35deg, #3e4c99 5.38%, #111065 93.7%);
    transform: skewY(3deg);
    position: absolute;
    top: 0;
    right: 0;
    left: -20%;
    bottom: 0;
    z-index: 2;
  }
  &::after {
    content: "";
    display: block;
    background: linear-gradient(272.35deg, #3e4c99 5.38%, #111065 93.7%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    z-index: 1;
  }
  a {
    color: white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: rgba(white, 0.6);
    }
  }
}
</style>