<template>
  <section id="sec-2">
    <div class="camion"></div>
    <LayoutSection>
      <template #title>Transport</template>
      <template #text>
        <p>
          Nous avons un camion frigorifique de 5 tonnes à votre disposition.<br />
          N’hésitez pas à nous contacter pour des besoins ponctuels ou
          reccurents.
        </p>
      </template>
    </LayoutSection>
    <span id="line-1" class="line">
      <svg height="160" width="160">
        <line
          x1="0"
          y1="0"
          x2="150"
          y2="150"
          style="stroke: rgba(255, 255, 255, 0.8); stroke-width: 1"
        />
      </svg>
    </span>
    <span id="line-2" class="line">
      <svg height="160" width="160">
        <line
          x1="0"
          y2="0"
          x2="150"
          y1="150"
          style="stroke: rgba(255, 255, 255, 0.8); stroke-width: 1"
        />
      </svg>
    </span>
  </section>
</template>

<script>
import LayoutSection from "./LayoutSection.vue";

export default {
  name: "Transport",
  components: {
    LayoutSection,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#sec-2 {
  color: white;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    $yellow 0%,
    $yellow 46%,
    rgba(255, 255, 255, 1) 49%
  );
  &::before {
    content: "";
    display: block;
    background: linear-gradient(90.96deg, #1772c6 11.71%, #3d4c98 92.4%);
    transform: skewY(-5deg);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
}
#mask-1 {
  top: 0;
}
#mask-2 {
  bottom: 0;
}
#line-1 {
  left: 0;
  top: -100px;
}
#line-2 {
  right: -60px;
  bottom: -10px;
}
.camion {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
  background: url(../assets/images/Camion_White.svg) no-repeat center center;
  background-size: 100%;
  width: 40%;
  height: 100%;
  //transform: skewY(5deg);
}
@media (min-width: 530px) {
  #line-1 {
    left: 8%;
    top: -80px;
  }
}
@media (min-width: 700px) {
  #line-1 {
    left: 20%;
    top: -50px;
  }
}
@media (min-width: 900px) {
  #line-1 {
    left: 25%;
    top: -30px;
  }
}
@media (min-width: 1200px) {
  #line-1 {
    left: 35%;
    top: -10px;
  }
  #line-2 {
    right: -30px;
    bottom: 30px;
  }
}
</style>